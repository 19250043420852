import React from "react"
import Layout from "../components/layout.js";
import Image from "../components/image.js";
import { Link } from "gatsby";
import config from "../../cronjob/config.js";

// markup
const IndexPage = () => {
  return (
    <Layout infos={{
      title: "All providers",
      description: "Discover and get all the latest icons from cloud services providers: "+Object.values(config.providers).map(e => e.name).join(", ")+" available in high quality",
      type: "home"
    }}>
    <h2>Find By Provider</h2>
      <ul id="providers">
      {
        Object.entries(config.providers).map(([slug, provider]) => {
          return (<li data-provider={slug}><Link
            to={ "/"+slug }
          >
            <Image src={ provider.icon } alt={ provider.name } />
          </Link></li>)
        })
      }
      </ul>
    </Layout>
  )
}

export default IndexPage
