const fixMetadata = (iconMetadata) => {
  //get sizes & light/dark information
  //remove last ancestor
  iconMetadata.ancestors = iconMetadata.ancestors.slice(0, -1);
  //get "@5X" png and make it preferred
  const parent = iconMetadata.path
    .split("/")
    .reverse()[1];
  const size = (
    [...parent.matchAll(/[-_](\d+)([-_]\w*)?$/g)][0]
    || [...parent.matchAll(/^(\d+)$/g)][0]
  )[1];
  iconMetadata.name = iconMetadata.name.replace(/^(Arch|Res|Arch Category) /gi, "")
  iconMetadata.variants[0].size = parseInt(size);
  iconMetadata.hasSize = true;
  if ((new RegExp(/@\d+x$/g)).test(iconMetadata.slug)) {
    iconMetadata.variants[0].size = iconMetadata.variants[0].size * [...iconMetadata.slug.matchAll(/@(\d+)x$/g)][0][1];
    iconMetadata.slug = iconMetadata.slug.replace(/_\d+@\d+x$/g, "");
  }
  const theme = [...parent.matchAll(/[-_](\d+)([-_](\w*))?$/g)][0];
  if (typeof theme !== "undefined" && theme[3] && theme[3].length) {
    iconMetadata.variants[0].theme = theme[3];
    iconMetadata.hasTheme = true;
  }
  return iconMetadata;
}
export default fixMetadata
