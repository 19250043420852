import fixAWS from "./aws.js";
import fixAzure from "./azure.js";

export default {
  categories_overrides: { //can be used to provide own categories instead of relying on the filesystem hierarchy
    //~ gcp: { //fist-level should be a provider's slug
      //~ dev_tools: { //key is used as slug 
        //~ name: "Developer Tools",
        //~ icons: [ //list of icons inside the category
          //~ "cloud_build", //reference an icon using the name of the file, without suffix
        //~ ],
        //~ subcategories: { //facultatively create nested categories by reproducing the same structure
          //~ dev_tools_subcategory: {
            //~ name: "A subcategory inside devtools",
            //~ icons: [
              //~ "cloud_code"
            //~ ],
            //~ subcategories: {
              //~ nested: {
                //~ name: "nested",
                //~ icons: [
                  //~ "cloud_sql"
                //~ ]
              //~ }
            //~ }
          //~ }
        //~ }
      //~ }
    gcp: {
      "ai_and_machine_learning": {
        "name": "AI and Machine Learning",
        "icons": [
          "cloud_vision_api",
          "speech-to-text",
          "cloud_natural_language_api",
          "cloud_translation_api",
          "video_intelligence_api",
          "automl",
          "cloud_tpu",
          "automl_video_intelligence",
          "automl_natural_language",
          "automl_translation",
          "automl_vision",
          "recommendations_ai",
          "automl_tables",
          "cloud_jobs_api",
          "cloud_inference_api",
          "data_labeling",
          "dialogflow",
          "text-to-speech",
          "vertexai",
          "document_ai",
          "visual_inspection",
          "retail_api",
          "ai_platform",
          "contact_center_ai",
          "agent_assist",
          "contact_center_ai_platform"
        ]
      },
      "compute": {
        "name": "Compute",
        "icons": [
          "compute_engine",
          "cloud_functions",
          "cloud_gpu",
          "bare_metal_solutions",
          "google_kubernetes_engine",
          "vmware_engine",
          "workflows",
          "distributed_cloud",
          "container_optimized_os",
          "app_engine",
          "cloud_run"
        ]
      },
      "analytics": {
        "name": "Analytics",
        "icons": [
          "dataproc",
          "dataflow",
          "pubsub",
          "genomics",
          "dataprep",
          "data_catalog",
          "cloud_composer",
          "cloud_data_fusion",
          "bigquery",
          "datastream",
          "cloud_healthcare_api",
          "looker",
          "data_studio",
          "dataplex",
          "analytics_hub"
        ]
      },
      "operations": {
        "name": "Operations",
        "icons": [
          "cloud_monitoring",
          "cloud_logging",
          "trace",
          "cloud_deployment_manager",
          "error_reporting",
          "debugger",
          "cloud_console",
          "cloud_shell",
          "google_cloud_app",
          "billing",
          "cloud_apis",
          "profiler",
          "cloud_deploy"
        ]
      },
      "networking": {
        "name": "Networking",
        "icons": [
          "virtual_private_cloud",
          "cloud_load_balancing",
          "cloud_cdn",
          "cloud_interconnect",
          "cloud_dns",
          "cloud_network",
          "cloud_external_ip_addresses",
          "cloud_firewall_rules",
          "cloud_routes",
          "cloud_vpn",
          "cloud_router",
          "partner_interconnect",
          "cloud_armor",
          "standard_network_tier",
          "premium_network_tier",
          "cloud_nat",
          "traffic_director",
          "network_intelligence_center"
        ]
      },
      "ci/cd": {
        "name": "CI/CD",
        "icons": [
          "cloud_sdk",
          "cloud_source_repositories",
          "tools_for_powershell",
          "tools_for_visual_studio",
          "maven_app_engine_plugin",
          "cloud_code_for_intellij",
          "cloud_tools_for_eclipse",
          "container_registry",
          "cloud_build",
          "gradle_app_engine_plugin",
          "ide_plugins",
          "cloud_code",
          "cloud_scheduler",
          "cloud_tasks",
          "cloud_test_lab",
          "artifact_registry"
        ]
      },
      "api_management": {
        "name": "API Management",
        "icons": [
          "api_analytics",
          "api_monetization",
          "apigee_api_platform",
          "cloud_endpoints",
          "developer_portal",
          "apigee_sense"
        ]
      },
      "internet_of_things": {
        "name": "Internet of Things",
        "icons": [
          "iot_core"
        ]
      },
      "databases": {
        "name": "Databases",
        "icons": [
          "datastore",
          "bigtable",
          "cloud_sql",
          "cloud_spanner",
          "memorystore",
          "firestore",
          "bigquery",
          "database_migration_service",
          "alloydb"
        ]
      },
      "storage": {
        "name": "Storage",
        "icons": [
          "cloud_storage",
          "persistent_disk",
          "filestore",
          "data_transfer"
        ]
      },
      "hybrid_and_multicloud": {
        "name": "Hybrid and Multicloud",
        "icons": [
          "traffic_director",
          "anthos",
          "anthos_clusters",
          "anthos_service_mesh"
        ]
      },
      "security": {
        "name": "Security",
        "icons": [
          "identity_and_access_management",
          "web_security_scanner",
          "key_management_service",
          "cloud_resource_manager",
          "security_command_center",
          "data_loss_prevention_api",
          "managed_service_for_microsoft_active_directory",
          "chronicle"
        ]
      },
      "migration": {
        "name": "Migration",
        "icons": [
          "transfer_appliance",
          "migrate_for_compute_engine",
          "migrate_for_anthos",
          "transfer",
          "data_transfer",
          "bigquery",
          "database_migration_service"
        ]
      },
      "open_source_icons": {
        "name": "Open Source Icons",
        "icons": [
          "kubernetes",
          "tensorflow",
          "forseti_security",
          "istio"
        ]
      },
      "misc": {
        "name": "Misc",
        "icons": [
          "access_context_manager",
          "administration",
          "advanced_agent_modeling",
          "advanced_solutions_lab",
          "ai_hub",
          "ai_platform_unified",
          "anthos_config_management",
          "api",
          "asset_inventory",
          "assured_workloads",
          "batch",
          "beyondcorp",
          "binary_authorization",
          "catalog",
          "certificate_authority_service",
          "certificate_manager",
          "cloud_api_gateway",
          "cloud_asset_inventory",
          "cloud_audit_logs",
          "cloud_domains",
          "cloud_ekm",
          "cloud_for_marketing",
          "cloud_generic",
          "cloud_healthcare_marketplace",
          "cloud_hsm",
          "cloud_ids",
          "cloud_media_edge",
          "cloud_ops",
          "cloud_optimization_ai",
          "cloud_optimization_ai_-_fleet_routing_api",
          "cloud_run_for_anthos",
          "cloud_security_scanner",
          "configuration_management",
          "connectivity_test",
          "connectors",
          "datalab",
          "data_layers",
          "datapol",
          "dataproc_metastore",
          "data_qna",
          "datashare",
          "dialogflow_cx",
          "dialogflow_insights",
          "early_access_center",
          "eventarc",
          "financial_services_marketplace",
          "fleet_engine",
          "free_trial",
          "game_servers",
          "gce_systems_management",
          "gke_on-prem",
          "google_cloud_marketplace",
          "google_maps_platform",
          "healthcare_nlp_api",
          "home",
          "identity-aware_proxy",
          "identity_platform",
          "iot_edge",
          "key_access_justifications",
          "kuberun",
          "launcher",
          "local_ssd",
          "media_translation_api",
          "my_cloud",
          "network_connectivity_center",
          "network_security",
          "network_tiers",
          "network_topology",
          "onboarding",
          "os_configuration_management",
          "os_inventory_management",
          "os_patch_management",
          "partner_portal",
          "performance_dashboard",
          "permissions",
          "phishing_protection",
          "policy_analyzer",
          "private_connectivity",
          "private_service_connect",
          "producer_portal",
          "project",
          "quantum_engine",
          "quotas",
          "real-world_insights",
          "release_notes",
          "risk_manager",
          "runtime_config",
          "secret_manager",
          "security",
          "security_health_advisor",
          "security_key_enforcement",
          "service_discovery",
          "stackdriver",
          "stream_suite",
          "support",
          "tensorflow_enterprise",
          "user_preferences",
          "web_risk",
          "workload_identity_pool"
        ]
      }
    }
  },
  providers: {
    gcp: { //key is used as a slug
      icon: "/gcp.svg", //absolute url or asbolute path (content of static dir)
      name: "Google Cloud Platform", //name used for icons hierarchy
      url: "https://cloud.google.com/icons/files/google-cloud-icons.zip", //url for assets download
      overrides: { //list of overrides, used to override the name of the icons
        "access_context_manager": "Access Context Manager" //key is the name of the icon without suffix / value is the desired name
      }
    },
    aws: {
      icon: "/aws.svg",
      name: "Amazon Web Services",
      url: "https://aws.amazon.com/architecture/icons/", //if no direct link, provide a webpage for url
      selector: "#Assets ~ a", //selector can be used to target the link to the icons,
      fixMetadata: fixAWS, //facultative function to fix metadata
      commands: [ //list of commands to perform on zip content after extraction
        "rm -rf ./__MACOSX"
      ]
    },
    azure: {
      icon: "/azure.svg",
      name: "Microsoft Azure",
      url: "https://docs.microsoft.com/en-us/azure/architecture/icons/",
      selector: "#icon-terms ~ .button a",
      fixMetadata: fixAzure,
      commands: [
        "cp -r ./Azure_Public_Service_Icons/Icons/* ./",
        "rm -rf ./Azure_Public_Service_Icons"
      ]
    },
  }
}
